// src/dependencies/useCaseInstances.ts

import { FeatureConfigItemRepositoryImpl, OfferRepositoryImpl, OurServicesRepositoryImpl, PropertyRepositoryImpl } from "../../data/repositories/RepositoryImpl";
import { GetFeatureConfigItemUseCase } from "./featureConfigItem/GetFeatureConfigItemUseCase";
import { GetOfferUseCase } from "./offer/GetOfferUeCase";
import { GetOurServicesrUseCase } from "./ourServices/GetOurServicesUseCase";
import { GetPropertiesUseCase } from "./properties/GetPropertiesUseCase";

const propertyRepository = new PropertyRepositoryImpl();
const offerRepository = new OfferRepositoryImpl()
const ourSevicesRepository = new OurServicesRepositoryImpl()
const featureConfigItemRepository = new FeatureConfigItemRepositoryImpl()
export const getPropertiesUseCaseIntance = new GetPropertiesUseCase(propertyRepository);
export const getOfferUseCaseInstance = new GetOfferUseCase(offerRepository)
export const getOurServicesUseCaseInstance = new GetOurServicesrUseCase(ourSevicesRepository)
export const getFeatureConfigItemUseCase = new GetFeatureConfigItemUseCase(featureConfigItemRepository)