import Contact from "../../components/contact/Contact";
const ContactPage: React.FC = () => {

    return (
      <div className="flex flex-col min-h-screen">
        <div className="flex-grow">
          <Contact/>
        </div>
      </div>
    );
  };
  
  export default ContactPage;