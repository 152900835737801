import { useEffect, useState } from "react";
import { OurService } from "../domain/model/OurServices";
import { getOurServicesUseCaseInstance } from "../domain/usecase/useCaseInstances";

const useFetchOurServices = () => {
    const [ourServices, setOurServices] = useState<OurService[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
  
    useEffect(() => {
      const fetchOurServices = async () => {
        setLoading(true);
        setError(null);
        try {
          const ourServicesData = await getOurServicesUseCaseInstance.execute();
          setOurServices(ourServicesData);
        } catch (err) {
          setError("Error fetching ourServices. "+ err);
          console.error("Error fetching properties:", err);
        } finally {
          setLoading(false);
        }
      };
  
      fetchOurServices();
    }, []);
  
    return { ourServices, loading, error };
  };
  
  export default useFetchOurServices;