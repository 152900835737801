import AboutUs from "../../components/aboutUs/AboutUs";

const AboutUsPage: React.FC = () => {

    return (
      <div className="flex flex-col min-h-screen">
        <div className="flex-grow">
          <AboutUs/>
        </div>
      </div>
    );
  };
  
  export default AboutUsPage;