import { useEffect, useState } from "react";
import useFetchOurServices from "../../hooks/useFetchOurServices";
import { toast, ToastContainer } from "react-toastify";
import { ThreeDots } from "react-loader-spinner";

const OurServices: React.FC = () => {
  const { ourServices, loading, error } = useFetchOurServices();
  const [expandedServiceIds, setExpandedServiceIds] = useState<{ [key: number]: boolean }>({});

  useEffect(() => {
    if (error) {
      toast.error('Error loading nuestros servicios. Please try again later.');
    } else if (!loading && (!ourServices || ourServices.length === 0)) {
      toast.info('No hay servicios disponibles en este momento.');
    }
  }, [error, loading, ourServices]);

  const toggleExpand = (id: number) => {
    setExpandedServiceIds((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const MAX_DESCRIPTION_LENGTH = 100;

  return (
    <div className="min-h-screen bg-white">
      {/* Header de la página */}
      <header className="flex flex-col items-center justify-center text-center py-12 bg-white shadow-md">
        <h1 className="text-3xl md:text-4xl font-bold text-gunmetal">Nuestros Servicios</h1>
        <p className="mt-4 text-charcoal text-base md:text-lg max-w-xl md:max-w-2xl mx-auto">
          Ofrecemos un portafolio de servicios inmobiliarios con soluciones adaptadas a cada necesidad.
        </p>
      </header>

      {/* Mostrar Loading Spinner */}
      {loading ? (
        <div className="flex justify-center items-center h-full py-12">
          <ThreeDots 
            height="80" 
            width="80" 
            radius="9"
            color="#4fa94d" 
            ariaLabel="three-dots-loading"
            visible={true}
          />
        </div>
      ) : (
        /* Sección de Servicios en un Grid */
        <section className="grid gap-4 grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 p-6 mt-8">
          {ourServices?.map((service) => {
            const isExpanded = expandedServiceIds[service.id];
            const shouldShowButton = service.description.length > MAX_DESCRIPTION_LENGTH;
            const displayDescription = isExpanded
              ? service.description
              : service.description.length > MAX_DESCRIPTION_LENGTH
                ? `${service.description.slice(0, MAX_DESCRIPTION_LENGTH)}...`
                : service.description;

            return (
              <div
                key={service.id}
                className="bg-white rounded-lg shadow-md overflow-hidden hover:shadow-xl transition-shadow duration-300 flex flex-col items-center text-center"
              >
                <img src={service.image} alt={service.title} className="h-64 w-full object-cover" />
                <div className="p-6">
                  <h2 className="text-2xl font-semibold text-charcoal">{service.title}</h2>
                  <p className="mt-2 text-charcoal">{displayDescription}</p>
                </div>
                <div className="p-6">
                  {shouldShowButton && (
                    <button
                      onClick={() => toggleExpand(service.id)}
                      className="w-full text-charcoal px-4 py-2 rounded hover:bg-gunmetal hover:text-white transition duration-300"
                    >
                      {isExpanded ? 'Ver menos' : 'Ver más'}
                    </button>
                  )}
                </div>
              </div>
            );
          })}
        </section>
      )}

      <ToastContainer />
    </div>
  );
};

export default OurServices;