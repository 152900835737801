import { useState } from 'react';
import 'leaflet/dist/leaflet.css';
import { LatLngTuple } from 'leaflet';
import emailIcon from '../../assets/email-icon.svg';
import phoneIcon from '../../assets/phone-icon.svg';
import locationIcon from '../../assets/location-icon.svg';
import L from 'leaflet';
import Footer from '../core/footer/Footer';
import MapComponent from '../core/MapComponent';

const Contact = () => {
  const [formData, setFormData] = useState({ name: '', email: '', message: '' });
  const [submitted, setSubmitted] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSubmitted(true);
  };

  const center: LatLngTuple = [-33.4489, -70.6693];

  return (
    <div className="min-h-screen bg-gunmetal">
      <header className="py-12 text-center bg-white text-gunmetal">
        <h1 className="text-4xl font-bold">Contáctanos</h1>
        <p className="mt-2 text-lg text-charcoal">Estamos aquí para ayudarte. ¡Envíanos un mensaje y nos pondremos en contacto!</p>
      </header>

      <div className="container mx-auto px-6 py-16 grid gap-8 md:grid-cols-2">
        {/* Formulario de contacto */}
        <div className="bg-white p-8 shadow-lg rounded-lg">
          <h2 className="text-2xl font-semibold text-gray-800 mb-6">Formulario de Contacto</h2>
          <form onSubmit={handleSubmit} className="space-y-4">
            <input
              type="text"
              name="name"
              placeholder="Nombre Completo"
              value={formData.name}
              onChange={handleChange}
              className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
              required
            />
            <input
              type="email"
              name="email"
              placeholder="Correo Electrónico"
              value={formData.email}
              onChange={handleChange}
              className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
              required
            />
            <textarea
              name="message"
              rows={5}
              placeholder="Mensaje"
              value={formData.message}
              onChange={handleChange}
              className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
              required
            />
            <div className="text-center mt-auto">
              <button
                type="submit"
                className="px-4 py-2 rounded bg-white text-charcoal border border-gunmetal font-semibold transition-all duration-300 hover:bg-gunmetal hover:text-white">
                Enviar Mensaje
              </button>
            </div>
          </form>
          {submitted && (
            <p className="mt-4 text-green-600 text-center">¡Gracias por tu mensaje! Nos pondremos en contacto pronto.</p>
          )}
        </div>

        {/* Información de contacto y mapa */}
        <div className="flex flex-col space-y-8">
          {/* Información de contacto */}
          <div className="bg-gray-100 p-8 rounded-lg shadow-lg">
            <h2 className="text-2xl font-semibold text-gray-800 mb-6">Nuestra Información</h2>
            <div className="space-y-4">
              <div className="flex items-center">
                <img src={phoneIcon} alt="Phone" className="w-6 h-6 mr-4" />
                <p className="text-gray-700">+56 9 8765 4321</p>
              </div>
              <div className="flex items-center">
                <img src={emailIcon} alt="Email" className="w-6 h-6 mr-4" />
                <p className="text-gray-700">contacto@inmotrust.cl</p>
              </div>
              <div className="flex items-center">
                <img src={locationIcon} alt="Location" className="w-6 h-6 mr-4" />
                <p className="text-gray-700">Av. Providencia 1234, Santiago, Chile</p>
              </div>
            </div>
          </div>

          {/* Mapa interactivo */}
          <MapComponent center={[-33.1830047, -70.6733444]} zoom={30} styleClass="h-64 rounded-lg shadow-lg" showPopup={true} />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Contact;