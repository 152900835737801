import FeaturedProperties from "../../components/home/FeaturedProperties";
import Footer from "../../components/core/footer/Footer";
const PropertiesPage: React.FC = () => {

    return (
      <div className="flex flex-col min-h-screen">
        <div className="flex-grow">
        <FeaturedProperties page="properties"/>
        </div>
        <Footer />
      </div>
    );
  };
  
  export default PropertiesPage;