import { FaSwimmingPool, FaCar, FaStore, FaWifi, FaWater, FaUtensils, FaLaptop, FaTv, FaBed, FaBath, FaBuilding, FaFaucet,FaFire, FaGasPump } from 'react-icons/fa';

export const featureConfig: { [key: string]: { icon: React.ReactNode; keywords: string[] } } = {
  'Piscina': { icon: <FaSwimmingPool />, keywords: ['piscina', 'pileta'] },
  'Estacionamiento': { icon: <FaCar />, keywords: ['estacionamiento', 'parking'] },
  'Bodega': { icon: <FaStore />, keywords: ['bodega', 'almacenamiento'] },
  'Wifi': { icon: <FaWifi />, keywords: ['wifi', 'internet'] },
  'Vista al mar': { icon: <FaWater />, keywords: ['vista al mar', 'mar'] },
  'Cocina Encimera Electrica': { icon: <FaUtensils />, keywords: ['cocina', 'comida'] },
  'Zona de trabajo': { icon: <FaLaptop />, keywords: ['zona de trabajo', 'oficina'] },
  'TV': { icon: <FaTv />, keywords: ['tv', 'televisión'] },
  'Habitaciones': { icon: <FaBed />, keywords: ['habitaciones', 'dormitorio'] },
  'Baño': { icon: <FaBath />, keywords: ['baño', 'aseo'] },
  'm²': { icon: <FaBuilding />, keywords: ['m²', 'metros cuadrados'] },
  'Quincho': { icon: <FaBuilding />, keywords: ['Quincho', 'quincho'] },
  'Sala MultiUso': { icon: <FaBuilding />, keywords: ['Sala MultiUso', 'sala multiUso'] },
  'Lavanderia': { icon: <FaBuilding />, keywords: ['Lavanderia', 'lavanderia'] },
  'Conexion Lavadora': { icon: <FaFaucet />, keywords: ['Conexion Lavadora', 'para poner lavadora'] },
  'Cocina a Gas': { icon: <FaBuilding />, keywords: ['Cocina a Gas', 'para poner lavadora'] },
  'Horno Electrico': { icon: <FaFire />, keywords: ['Horno Electrico', 'para poner lavadora'] },
  'Horno a Gas': { icon: <FaGasPump />, keywords: ['Horno a Gas', 'para poner lavadora'] },
}; 