import React, { useState } from 'react';
import { Property } from '../../domain/model/Property';
import { MapContainer, TileLayer, Marker } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { LatLngExpression } from 'leaflet';
import PropertyFeatures from './PropertyFeature';
import locationIcon from '../../assets/location-icon.svg';
import MapComponent from './MapComponent';

interface PropertyDetailsModalProps {
  property: Property;
  onClose: () => void;
}

const PropertyDetailsModal: React.FC<PropertyDetailsModalProps> = ({ property, onClose }) => {
  const images = [property.image, ...property.images.filter((img) => img !== property.image)];
  const [selectedImage, setSelectedImage] = useState(images[0]);

  if (!property) return null;

  const location: LatLngExpression = [property.location.latitude, property.location.longitude];

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-5xl h-[90vh] overflow-y-auto relative flex flex-col">
        <button onClick={onClose} className="absolute top-2 right-2 text-gunmetal hover:text-charcoal text-2xl">
          ×
        </button>

        <div className="flex mb-6">
          <div className="flex-1 h-96 md:h-[500px] rounded-lg overflow-hidden shadow-lg relative">
            <img
              src={selectedImage}
              alt={property.name}
              className="w-full h-full object-cover"
            />
          </div>

          <div className="flex flex-col gap-2 overflow-y-auto h-96 md:h-[500px] ml-4">
            {images.map((image, index) => (
              <img
                key={index}
                src={image}
                alt={`Thumbnail ${index + 1}`}
                onClick={() => setSelectedImage(image)}
                className={`w-20 h-20 object-cover rounded-lg cursor-pointer transition transform hover:scale-105 ${image === selectedImage ? 'border-2 border-gunmetal' : 'border border-gray-300'
                  }`}
              />
            ))}
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <PropertyFeatures
            features={property.features}
            layout="details"
            address={property.address}
            price={property.price}
            isAvailable={property.status === 'Disponible'}
          />
          <MapComponent center={location} zoom={30} styleClass="w-full h-72 md:h-96 rounded-lg shadow-md" showPopup={false} />
        </div>
      </div>
    </div>
  );
};

export default PropertyDetailsModal;