import React from 'react';
import { FaHome } from 'react-icons/fa';
import { featureConfig } from '../../core/featureConfig';

interface FeatureIconProps {
  feature: string;
}

// Función para obtener el icono en función de las palabras clave
const getFeatureIcon = (feature: string) => {
  for (const key in featureConfig) {
    const { icon, keywords } = featureConfig[key];
    // Coincidencia flexible: si alguna palabra clave se encuentra en el texto, se asigna el icono
    if (keywords.some((keyword) => feature.toLowerCase().includes(keyword.toLowerCase()))) {
      return icon;
    }
  }
  return <FaHome />; // Icono predeterminado si no hay coincidencia
};

const FeatureIcon: React.FC<FeatureIconProps> = ({ feature }) => {
  return <span className="mr-1">{getFeatureIcon(feature)}</span>;
};

export default FeatureIcon;