import { useEffect, useState } from "react";
import { Offer } from "../domain/model/Offer";
import { getOfferUseCaseInstance } from "../domain/usecase/useCaseInstances";

const useFetchOffer = () => {
    const [offer, setOffer] = useState<Offer[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
  
    useEffect(() => {
      const fetchOffer = async () => {
        setLoading(true);
        setError(null);
        try {
          const offerData = await getOfferUseCaseInstance.execute();
          setOffer(offerData);
        } catch (err) {
          setError("Error fetching offer. "+ err);
          console.error("Error fetching properties:", err);
        } finally {
          setLoading(false);
        }
      };
  
      fetchOffer();
    }, []);
  
    return { offer, loading, error };
  };
  
  export default useFetchOffer;