import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from '../pages/home/Home';
import OurServicePages from '../pages/services/OurServicesPages';
import PropertiesPage from '../pages/properties/PropertiesPage';
import AboutUsPage from '../pages/aboutus/AboutUsPage';
import ContactPage from '../pages/contact/ContactPage';

const AppRoutes: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="servicios" element={<OurServicePages />} />
        <Route path="propiedades" element={<PropertiesPage />} />
        <Route path="sobreNosotros" element={<AboutUsPage />} />
        <Route path="contacto" element={<ContactPage />} />
      </Routes>
    </Router>
  );
};

export default AppRoutes;