import { initializeApp } from 'firebase/app';
import { getFirestore, Firestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: 'AIzaSyCaHk74I89viCTCY9hhyX_0jyiJZqQByg4',
  authDomain: 'inmotrust-92df0.firebaseapp.com',
  projectId: 'inmotrust-92df0',
  storageBucket: 'inmotrust-92df0.firebasestorage.app',
  messagingSenderId: '866721303593',
  appId: "1:866721303593:web:982779f60d9af2e1ebe038",
  measurementId: "G-R536MEQ21H"
};

// Inicializa Firebase
const app = initializeApp(firebaseConfig);

// Inicializa Firestore
const db: Firestore = getFirestore(app);

export {db as firebaseFirestore} ;