// src/pages/Home/OffersSlider.tsx
import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import useFetchOffer from '../../hooks/useFetchOffer';


const OffersSlider: React.FC = () => {
  const { offer} = useFetchOffer();

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
  };



  return (
    <div className="my-4 mx-4">
      <Slider {...settings}>
        {offer.map((result) => (
          <div key={result.id} className="relative h-[350px]">
            <img src={result.image} className="w-full h-full object-cover rounded-lg" alt='Descripción de la oferta' />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default OffersSlider;