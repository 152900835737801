import { useState, useEffect } from 'react';
import { Property } from '../domain/model/Property';
import { FeatureConfigItem } from '../domain/model/FeatureConfigItem';
import { getPropertiesUseCaseIntance, getFeatureConfigItemUseCase } from '../domain/usecase/useCaseInstances';

const useFetchPropertiesWithConfig = () => {
  const [properties, setProperties] = useState<Property[]>([]);
  const [featureConfigItem, setFeatureConfigItem] = useState<FeatureConfigItem[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);

      try {
        // Primero, obtenemos los FeatureConfigItem
        const featureData = await getFeatureConfigItemUseCase.execute();

        console.log("featureData:", JSON.stringify(featureData, null, 2));
        setFeatureConfigItem(featureData);

        // Una vez que tenemos los FeatureConfigItem, obtenemos las propiedades
        const propertiesData = await getPropertiesUseCaseIntance.execute();
        if (propertiesData != null) {
          setProperties(propertiesData);
        }
      } catch (err) {
        setError("Error fetching data. " + err);
        console.error("Error fetching data:", err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return { properties, featureConfigItem, loading, error };
};

export default useFetchPropertiesWithConfig;