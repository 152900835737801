import React from 'react';
import OffersSlider from '../../components/home/OffersSlider';
import FeaturedProperties from '../../components/home/FeaturedProperties';
import Footer from '../../components/core/footer/Footer';

const Home: React.FC = () => {

  return (
    <div className="flex flex-col min-h-screen">
      <div className="flex-grow">
        <OffersSlider />
         <FeaturedProperties page="home" />
      </div>
      <Footer />
    </div>
  );
};

export default Home;