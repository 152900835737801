import Navbar from "./components/Navbar/Navbar";
import AppRoutes from "./routes/AppRoutes";

function App() {
  return (
    <div >
      <Navbar/>
      <AppRoutes/>
  </div>
  );
}

export default App;
