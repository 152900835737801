import React, { useEffect, useState } from 'react';
import { ThreeDots } from 'react-loader-spinner';
import useFetchProperties from '../../hooks/useFetchProperties';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PropertyDetailsModal from '../core/PropertyDetailsModal';
import { Property } from '../../domain/model/Property';
import PropertyFeatures from '../core/PropertyFeature';

const FeaturedProperties: React.FC<{ page: 'home' | 'properties' }> = ({ page }) => {
  const { properties, loading, error } = useFetchProperties();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedProperty, setSelectedProperty] = useState<Property | null>(null);
  const [loadedImages, setLoadedImages] = useState<{ [key: string]: boolean }>({});

  const displayedProperties = page === 'home'
    ? (() => {
        const highlightedProperty = properties.find(property => property?.highlightedText);
        const otherProperties = properties.filter(property => !property?.highlightedText);

        if (highlightedProperty) {
          return [
            otherProperties[0] ?? highlightedProperty,
            highlightedProperty,
            otherProperties[1] ?? highlightedProperty,
          ];
        }
        return properties.slice(0, 3).filter(Boolean);
      })()
    : properties.slice().sort((a, b) => (b.highlightedText ? 1 : 0) - (a.highlightedText ? 1 : 0));

  useEffect(() => {
    if (error) {
      toast.error('Error loading properties. Please try again later.');
    }
  }, [error]);

  const openModal = (property: Property) => {
    setSelectedProperty(property);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setTimeout(() => setSelectedProperty(null), 300);
  };

  const handleImageLoad = (propertyId: string) => {
    setLoadedImages(prev => ({ ...prev, [propertyId]: true }));
  };

  return (
    <div className="my-8 px-4 lg:pt-8">
      {loading ? (
        <div className="flex justify-center items-center h-full">
          <ThreeDots
            height="80"
            width="80"
            radius="9"
            color="#4fa94d"
            ariaLabel="three-dots-loading"
            visible={true}
          />
        </div>
      ) : (
        <>
          <h2 className="text-2xl font-bold text-center mb-4 lg:mb-8 text-gunmetal">
            {page === "properties" ? "Lista de Propiedades" : "Propiedades Destacadas"}
          </h2>
          <div className={`${page === "properties" ? 'grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 lg:mt-10' : 'grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 lg:mt-10'}`}>
            {displayedProperties.map((property) => (
              <div
                key={property.id}
                className={`relative border rounded-lg overflow-hidden shadow-md flex flex-col ${property.highlightedText && page === "properties" ? 'lg:-mt-0' :
                    property.highlightedText ? 'border-1 border-gunmetal pt-12 mt-4 sm:mt-0 lg:-mt-8' : 'border border-gray-300'
                  } transition-all duration-300 ${property.highlightedText ? 'order-1 lg:order-1' : 'order-2 lg:order-1'}`}
              >
                {property.highlightedText && (
                  <div className="absolute w-full top-0 left-1/2 transform -translate-x-1/2 bg-gunmetal text-white text-sm sm:text-base font-bold px-4 py-1 rounded-t-lg inline-block text-center">
                    {property.highlightedText}
                  </div>
                )}
                
                {/* Contenedor de la imagen con loading */}
                <div
                  className={`relative w-full ${!loadedImages[String(property.id)] ? 'h-48' : ''}`}
                >
                  {!loadedImages[String(property.id)] && (
                    <div className="absolute inset-0 flex items-center justify-center bg-gray-100 z-10">
                      <ThreeDots height="40" width="40" color="#4fa94d" ariaLabel="loading" />
                    </div>
                  )}
                  <img
                    src={property.image}
                    alt={property.name}
                    className={`w-full object-cover rounded ${loadedImages[String(property.id)] ? '' : 'hidden'}`}
                    onLoad={() => handleImageLoad(String(property.id))}
                  />
                </div>

                {/* Detalles de la propiedad */}
                <div className="p-4 flex-grow flex flex-col">
                  <div className="text-center mb-4">
                    <h3 className="text-lg sm:text-xl font-semibold">{property.name}</h3>
                    <p className="text-gray-500 text-sm">{property.address}</p>
                    <p className="text-gray-700 font-medium text-lg">{property.price}</p>
                  </div>
                  <PropertyFeatures features={property.features} layout="home" />
                  <div className="text-center mt-auto">
                    <button
                      onClick={() => openModal(property)}
                      className="px-4 py-2 rounded bg-white text-charcoal border border-gunmetal font-semibold transition-all duration-300 hover:bg-gunmetal hover:text-white"
                    >
                      Ver propiedad
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>

          {isModalOpen && selectedProperty && (
            <PropertyDetailsModal property={selectedProperty} onClose={closeModal} />
          )}
        </>
      )}
      <ToastContainer />
    </div>
  );
};

export default FeaturedProperties;