import React, { useState } from 'react';
import MenuIcon from '../../assets/MenuIcon';

const Navbar: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="bg-gunmetal text-white p-4 shadow-md">
      <div className="container mx-auto flex items-center justify-between">
        {/* Título de la aplicación */}
        <h1 className={`text-2xl font-bold ${isOpen ? 'mx-auto' : ''}`}>Inmotrust</h1>

        {/* Botón de menú hamburguesa para dispositivos móviles */}
        <button
          onClick={toggleMenu}
          className="md:hidden text-white focus:outline-none"
        >
          {/* Icono de menú hamburguesa */}
          <MenuIcon className="w-6 h-6" />
        </button>

        {/* Menú de navegación */}
        <ul
          className={`flex flex-col md:flex-row md:space-x-6 absolute md:static top-16 left-0 w-full md:w-auto bg-gunmetal md:bg-transparent transition-transform ${
            isOpen ? 'block' : 'hidden md:flex'
          } z-50 md:z-auto`}
        >
          <li className="my-2 md:my-0 mx-4">
            <a href="/" className="block py-2 px-4 rounded-lg hover:outline hover:outline-charcoal hover:bg-transparent transition-all">
              Inicio
            </a>
          </li>
          <li className="my-2 md:my-0 mx-4">
            <a href="/servicios" className="block py-2 px-4 rounded-lg hover:outline hover:outline-charcoal hover:bg-transparent transition-all">
              Servicios
            </a>
          </li>
          <li className="my-2 md:my-0 mx-4">
            <a href="/propiedades" className="block py-2 px-4 rounded-lg hover:outline hover:outline-charcoal hover:bg-transparent transition-all">
              Propiedades
            </a>
          </li>
          <li className="my-2 md:my-0 mx-4">
            <a href="/sobreNosotros" className="block py-2 px-4 rounded-lg hover:outline hover:outline-charcoal hover:bg-transparent transition-all">
              Sobre Nosotros
            </a>
          </li>
          <li className="my-2 md:my-0 mx-4">
            <a href="/contacto" className="block py-2 px-4 rounded-lg hover:outline hover:outline-charcoal hover:bg-transparent transition-all">
              Contacto
            </a>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;