import { Property } from '../../domain/model/Property';
import { FeatureConfigItemRepository, OfferRepository, OurServicesRepository, PropertyRepository } from '../../domain/repositories/Repository';
import { firebaseFirestore } from '../../core/firebaseFirestore';
import { collection, getDocs, QueryDocumentSnapshot } from 'firebase/firestore';
import { Offer } from '../../domain/model/Offer';
import { OurService } from '../../domain/model/OurServices';
import { FeatureConfigItem } from '../../domain/model/FeatureConfigItem';

export class PropertyRepositoryImpl implements PropertyRepository {
    async getProperties(): Promise<Property[]> {
      const propertiesCollection = collection(firebaseFirestore, 'properties');
      const snapshot = await getDocs(propertiesCollection);
      return snapshot.docs.map((doc: QueryDocumentSnapshot) => ({
        ...doc.data() as Property,
      }));
    }
  }
 
  export class OfferRepositoryImpl implements OfferRepository {
    async getOffer(): Promise<Offer[]> {
        const offersCollection = collection(firebaseFirestore, 'offers');
        const snapshot = await getDocs(offersCollection);
        return snapshot.docs.map((doc: QueryDocumentSnapshot) => ({
            ...doc.data() as Offer,
        }));
    }
  }

  export class OurServicesRepositoryImpl implements OurServicesRepository {
    async getOurServices(): Promise<OurService[]> {
        const ourServicesCollection = collection(firebaseFirestore, 'OurServices');
        const snapshot = await getDocs(ourServicesCollection);
        return snapshot.docs.map((doc: QueryDocumentSnapshot) => ({
            ...doc.data() as OurService,
        }));
    }
  }

  export class FeatureConfigItemRepositoryImpl implements FeatureConfigItemRepository {
    async getFeatureConfigItem(): Promise<FeatureConfigItem[]> {
        const featureConfigItemCollection = collection(firebaseFirestore, 'featureIcons');
        const snapshot = await getDocs(featureConfigItemCollection);
        return snapshot.docs.map((doc: QueryDocumentSnapshot) => ({
            ...doc.data() as FeatureConfigItem,
        }));
    }
  }
