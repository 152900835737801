import Footer from "../../components/core/footer/Footer";
import OurServices from "../../components/services/OurServices";

const OurServicePages: React.FC = () => {
    return (
      <div className="flex flex-col min-h-screen">
        <div className="flex-grow">
          <OurServices/>
        </div>
        <Footer />
      </div>
    );
  };
  
  export default OurServicePages;